import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import HeaderTop from "../components/HeaderTop"
import HeaderColor from "../components/HeaderColor"
// import ButtonWrapper from "../components/Button"
import ButtonRegister from "../components/ButtonRegister"
import FooterTop from "../components/FooterTop"
import Footer from "../components/Footer"
import options from "../../config/options"
import { Row, Col } from "antd"
import styled from "@emotion/styled"
import CallToAction from "../components/CallToAction"
import airMobile from "../../static/images/mobile-air.png"
import cenPumpMobile from "../../static/images/mobile-centrifugal-pump.png"
import blogMobile from "../../static/images/mobile-blog.png"
import airDesktop from "../../static/images/desktop-air.jpg"
import cenPumpDesktop from "../../static/images/desktop-centrifugal-pump.jpg"
import blogDesktop from "../../static/images/desktop-blog.jpg"

const HeroSectionWrapper = styled.div`
  background: #3e5b75;
  position: relative;
  .heroContent {
    min-height: calc(100vh - 48px);
    height: calc(100vh - 48px);
    position: relative;
  }
  .heroImage {
    width: 100%;
    position: absolute;
    right: auto;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .contentWrapper {
    margin-top: -45%;
    p {
      font-family: "Prompt", sans-serif;
      font-weight: 300;
      font-size: 16px;
      color: #fff;
      margin: 0;
    }
    h1 {
      font-family: "Prompt", sans-serif;
      font-weight: 500;
      font-size: 32px;
      color: #fff;
      margin-top: 10px;
      margin-bottom: 0;
    }
    h2 {
      font-family: "Prompt", sans-serif;
      font-weight: 400;
      font-size: 18px;
      color: #fff;
      margin-bottom: 0;
    }
  }
  .divWrapper {
    margin-top: 25px;
  }
  @media only screen and (min-width: 576px) {
    .heroContent {
      min-height: 100%;
      height: 442px;
    }
    .heroImage {
      width: 55%;
      right: -15px;
      left: auto;
      transform: none;
    }
    .contentWrapper {
      margin-top: 100px;
    }
  }
  @media only screen and (min-width: 768px) {
    .contentWrapper {
      p {
        font-size: 16px;
      }
      h1 {
        font-size: 38px;
      }
      h2 {
        font-size: 26px;
      }
    }
  }
  @media only screen and (min-width: 992px) {
    .heroContent {
      height: 576px;
    }
    .heroImage {
      width: 60%;
    }
    .contentWrapper {
      p {
        font-size: 18px;
      }
      h1 {
        font-size: 48px;
      }
      h2 {
        font-size: 32px;
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    .heroContent {
      height: 824px;
    }
    .heroImage {
      width: 62%;
    }
    .contentWrapper {
      p {
        font-size: 20px;
      }
      h1 {
        font-size: 62px;
      }
      h2 {
        font-size: 40px;
      }
    }
  }
  @media only screen and (max-width: 767px) and (orientation: landscape) {
    .heroContent {
      min-height: 100%;
      height: 442px;
    }
    .heroImage {
      width: 55%;
      right: -15px;
      left: auto;
      transform: none;
    }
    .contentWrapper {
      margin-top: 100px;
    }
  }
  @media only screen and (max-width: 320px) {
    .heroImage {
      width: 90%;
    }
    .contentWrapper {
      margin-top: -35%;
      p {
        font-size: 16px;
      }
      h1 {
        font-size: 28px;
      }
      h2 {
        font-size: 20px;
      }
    }
  }
`
const IntroSectionWrapper = styled.div`
  padding: 100px 0;
  margin: 50px auto 0 auto;
  text-align: center;
  background: #ac0402;
  .sub {
    font-family: "Prompt", sans-serif;
    font-weight: 600;
    font-size: 26px;
    color: #fff500;
    margin-bottom: 0;
  }
  .title {
    font-family: "Prompt", sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #fff;
    margin-top: 15px;
    margin-bottom: 20px;
  }
  .text {
    font-family: "Prompt", sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #000;
  }
  @media only screen and (min-width: 768px) {
    .sub,
    .title {
      font-size: 32px;
    }
  }
  @media only screen and (min-width: 1200px) {
    .sub,
    .title {
      font-size: 40px;
    }
  }
`
const ZigzagSectionWrapper = styled.div`
  .zigzagOdd {
    padding: 80px 0;
    background: #ffffff;
    ul.listCheck {
      li {
        color: #000;
      }
    }
  }
  .zigzagEven {
    padding: 80px 0;
    background: #ac0402;
    ul.listCheck {
      li {
        color: #fff;
      }
    }
  }
  .title {
    text-align: center;
  }
  .spaceWrapper {
    margin: 30px auto 0 auto;
    text-align: center;
  }
  ul.listCheck {
    margin-left: 0;
  }
  @media only screen and (min-width: 576px) {
    ul.listCheck {
      margin-left: 10%;
    }
  }
  @media only screen and (min-width: 992px) {
    .title {
      text-align: left;
    }
    .spaceWrapper {
      margin: 30px 0 0 0;
      text-align: left;
    }
    ul.listCheck {
      margin-left: 0;
    }
  }
`

const HeroCalltoaction = styled.div`
  text-align: center;
  padding-top: 50px;
  .menuMobileWrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-bottom: 1rem;
    .items a {
      display: flex;
      justify-content: center;
    }
    .items:nth-child(3) {
      grid-column: span 2;
    }
  }
  .flexCenter {
    display: none;
  }
  @media only screen and (min-width: 992px) {
    .menuMobileWrapper {
      display: none;
    }
    .flexCenter {
      display: flex;
      justify-content: center;
      .menuDesktopWrapper {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 20px;
        margin-bottom: 1rem;
        max-width: 1335px;
        padding: 0px 15px;
        .items {
          overflow: hidden;
        }
        .items:nth-child(3) {
          grid-column: span 2;
        }
        .items img {
          transition: transform .3s;
        }
        .items:hover img {
          transform: scale(1.1);
          transform-origin: 50% 50%;
        }
      }
    }
  }
`

const EmbedContainer = styled.div`
  position: relative;
  padding-top: 56.25%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`

const IndexPage = () => {
  const { heroSection, zigzagSection } = useStaticQuery(
    graphql`
      query {
        heroSection: allContentfulHome {
          edges {
            node {
              seoTitle
              seoDescription
              heroSub
              heroTitle
              heroContent
              heroImage {
                fluid(maxWidth: 750) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              heroBackgroundDesktop {
                fluid(maxWidth: 1920) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              heroBackgroundMobile {
                fluid(maxWidth: 750) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              introSub
              introTitle
              introText
            }
          }
        }
        zigzagSection: allContentfulHomeZigzag {
          edges {
            node {
              title
              content {
                content
              }
              image {
                fluid(maxWidth: 750) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              video
            }
          }
        }
      }
    `
  )

  const {
    seoTitle,
    seoDescription,
    // heroSub,
    // heroTitle,
    // heroContent,
    heroImage,
    heroBackgroundDesktop,
    heroBackgroundMobile,
    introSub,
    introTitle,
    // introText,
  } = heroSection.edges[0].node
  const sources = [
    heroBackgroundMobile.fluid,
    {
      ...heroBackgroundDesktop.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <Layout>
      <SEO title={seoTitle} description={seoDescription} />
      <CallToAction />
      <HeaderTop text={options.headerTop} />
      <HeroSectionWrapper>
        <HeaderColor />
        <BackgroundImage Tag={`section`} fluid={sources} fadeIn={false}>
          <div className="gb-container">
            <Row align="middle" className="heroContent">
              <Col xs={24} md={20} lg={18} xl={16} className="contentWrapper">
                {/* <p dangerouslySetInnerHTML={{ __html: heroSub }}></p>
                <h1 dangerouslySetInnerHTML={{ __html: heroTitle }}></h1>
                <h2 dangerouslySetInnerHTML={{ __html: heroContent }}></h2> */}
                {/* <div className="divWrapper">
                  <ButtonWrapper
                    text={options.lineText}
                    cta={options.lineCTA}
                    path="target"
                  />
                </div> */}
              </Col>
              <div className="heroImage">
                {heroImage ? (
                  <a
                    href={options.youtubeUrl}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Youtube url"
                  >
                    <Img
                      loading="eager"
                      fadeIn={false}
                      fluid={heroImage.fluid}
                    />
                  </a>
                ) : null}
              </div>
            </Row>
          </div>
        </BackgroundImage>
      </HeroSectionWrapper>
      <HeroCalltoaction>
        <div className="menuMobileWrapper">
          <div className="items">
              <Link to="/pumpsolarcell/">
                <img src={cenPumpMobile} width="100%" alt="centrifugal pump" />
              </Link>
          </div>
          <div className="items">
              <Link to="/airsolarcell/">
                <img src={airMobile} width="100%" alt="air" />
              </Link>
          </div>
          <div className="items">
              <Link to="/blog/">
                <img src={blogMobile} width="100%" alt="blog" />
              </Link>
          </div>
        </div>
        <div className="flexCenter">
          <div className="menuDesktopWrapper">
            <div className="items">
                <Link to="/pumpsolarcell/">
                  <img src={cenPumpDesktop} width="100%" alt="centrifugal pump" />
                </Link>
            </div>
            <div className="items">
                <Link to="/airsolarcell/">
                  <img src={airDesktop} width="100%" alt="air" />
                </Link>
            </div>
            <div className="items">
                <Link to="/blog/">
                  <img src={blogDesktop} width="100%" alt="blog" />
                </Link>
            </div>
          </div>
        </div>
        <div className="spaceWrapper">
          <ButtonRegister
            text="สอบถามฟรี!"
            click="คลิกที่นี่"
            subtext="กดแอดไลน์ @AECbrand"
            cta={options.lineCTA}
            path="target"
          />
        </div>
      </HeroCalltoaction>
      <IntroSectionWrapper>
        <div className="gb-container">
          <Row>
            <Col span={24}>
              <p className="sub">{introSub}</p>
              <h2
                className="title"
                dangerouslySetInnerHTML={{ __html: introTitle }}
              ></h2>
              {/* <div
                className="text"
                dangerouslySetInnerHTML={{ __html: introText }}
              /> */}
            </Col>
          </Row>
        </div>
      </IntroSectionWrapper>
      <ZigzagSectionWrapper className="sectionWrapper">
        {zigzagSection.edges.map((zigzag, index) => {
          if (index % 2 === 0) {
            return (
              <div key={index} className="zigzagOdd">
                <div className="gb-container">
                  <Row gutter={[48, 8]}>
                    <Col
                      xs={{ span: 24, offset: 0 }}
                      sm={{ span: 18, offset: 3 }}
                      lg={{ span: 12, offset: 0 }}
                    >
                      {zigzag.node.video ? (
                        <EmbedContainer>
                          <iframe
                            width="100%"
                            src={`${zigzag.node.video}&autoplay=1&mute=1`}
                            title={zigzag.node.title}
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            frameBorder="0"
                            webkitallowfullscreen="true"
                            mozallowfullscreen="true"
                            allowFullScreen
                          ></iframe>
                        </EmbedContainer>
                      ) : (
                        <Img
                          loading="eager"
                          fadeIn={false}
                          fluid={zigzag.node.image.fluid}
                        />
                      )}
                    </Col>
                    <Col xs={24} lg={12}>
                      <h2
                        className="title"
                        dangerouslySetInnerHTML={{ __html: zigzag.node.title }}
                      ></h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: zigzag.node.content.content,
                        }}
                      />
                      <div className="spaceWrapper">
                        <ButtonRegister
                          text="สอบถามฟรี!"
                          click="คลิกที่นี่"
                          subtext="กดแอดไลน์ @AECbrand"
                          cta={options.lineCTA}
                          path="target"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            )
          } else {
            return (
              <div key={index} className="zigzagEven">
                <div className="gb-container">
                  <Row gutter={[8, 48]}>
                    <Col
                      xs={{ span: 24, order: 2 }}
                      lg={{ span: 12, order: 1 }}
                    >
                      <h2
                        className="title yellow"
                        dangerouslySetInnerHTML={{ __html: zigzag.node.title }}
                      ></h2>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: zigzag.node.content.content,
                        }}
                      />
                      <div className="spaceWrapper">
                        <ButtonRegister
                          text="สอบถามฟรี!"
                          click="คลิกที่นี่"
                          subtext="กดแอดไลน์ @AECbrand"
                          cta={options.lineCTA}
                          path="target"
                        />
                      </div>
                    </Col>
                    <Col
                      xs={{ span: 24, offset: 0, order: 1 }}
                      sm={{ span: 18, offset: 3, order: 1 }}
                      lg={{ span: 12, offset: 0, order: 2 }}
                    >
                      {zigzag.node.video ? (
                        <EmbedContainer>
                          <iframe
                            width="100%"
                            src={`${zigzag.node.video}&autoplay=1&mute=1`}
                            title={zigzag.node.title}
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            frameBorder="0"
                            webkitallowfullscreen="true"
                            mozallowfullscreen="true"
                            allowFullScreen
                          ></iframe>
                        </EmbedContainer>
                      ) : (
                        <Img
                          loading="eager"
                          fadeIn={false}
                          fluid={zigzag.node.image.fluid}
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            )
          }
        })}
      </ZigzagSectionWrapper>
      <FooterTop />
      <Footer />
    </Layout>
  )
}
export default IndexPage
